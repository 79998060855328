import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTransactionsStore = defineStore('transactions', () => {
  const filtersFormattedForApi = ref({})

  return {
    filtersFormattedForApi,
  }
})
