import type { LuiIconName, LuiSelectOption } from '@loomispay/loomis-ui'
import { localisedCategoryForTransaction, localisedStatusForTransaction } from '@/utils/string/cardTransactions'

import type {
    PortalTransaction,
    PortalTransactionAttributes,
  } from '@/interfaces/PortalTransaction'

export const iconForTransaction = ({ attributes }: PortalTransaction): LuiIconName | null => {
  switch (attributes.category) {
    case 'refund':
      return 'refund'
    case 'reversal':
      return 'arrow-left-right'
    case 'chargeback':
    case 'balance-reset':
      return 'info'
    case 'purchase':
      return attributes.status === 'declined' ? 'warning-circled' : null
    default:
      return null
  }
}

export const classForTransaction = ({ attributes }: PortalTransaction) => {
  switch (attributes.status) {
    case 'on_hold':
      return 'tw-text-warning'
    case 'declined':
      return 'tw-text-danger'
    case 'approved':
      return 'tw-text-success'
    default:
      return ''
  }
}

export const categoryStatusForTransaction = (transaction: PortalTransaction) => {
  const { category: orgCategory, status: orgStatus } = transaction.attributes
  let status = localisedStatusForTransaction(transaction)
  const category = localisedCategoryForTransaction(transaction)
  // For purchases we only want to show if the transaction was declined
  if (orgCategory === 'purchase' && orgStatus === 'declined') {
    return status
  }
  
  // For other categories, we want to show the category and status
  if (orgCategory !== 'purchase') {
    return `${category} ${status.toLowerCase()}`
  }

  return ''
}

/**
 * Formats the filters for the API.
 * Since the status is an enum, but we want the UI filter to be a checkbox, we need to convert the boolean to the enum.
 * The 'none' status is only supported for ISO merchants, so we only add it if the merchant is ISO.
 * @param query
 * @param merchant
 * @returns {*&{status: (string), include_declined: undefined}}
 */
export const formatFiltersForApi = (query: any, merchant: any) => {
 
  const isIsoLicense = merchant!.attributes.license === 'ISO'
  const includeDeclined = query.include_declined === 'true' || query.include_declined === true

  // Construct the status string based on the merchant license type and whether declined transactions are included.
  const statusOptions: Array<PortalTransactionAttributes['status']> = ['approved']
  if (includeDeclined) {
    statusOptions.push('declined')
  }
  if (isIsoLicense) {
    statusOptions.push('none')
  } else {
    statusOptions.push('on_hold')
  }
  const status = statusOptions.join(',')

  // Return the modified query object with the new status and without the include_declined property.
  const modifiedQuery = { ...query, status }
  delete modifiedQuery.include_declined

  

  return modifiedQuery
}
